/* index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* Global Styles */
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1F242E; /* Dark, muted text color */
}

code {
  font-family: 'DM Sans', monospace; /* Optional: Consistent font for code */
}

.phone-input-custom .PhoneInputInput {
  border: 1px solid #d1d5db; /* Match with other input styles */
  padding: 0.5rem;
  outline: none; /* Remove default outline */
  border-radius: 7px;
  margin: .5rem auto;
}

.phone-input-custom .PhoneInputInput:focus {
  border-color: #4F46E5; /* Change to desired focus color */
}


:root {
  /* Background Colors */
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #121212; /* Updated to Dodger Blue for better complementarity */
  --toastify-color-success: #121212;
  --toastify-color-warning: #E8AB30;
  --toastify-color-error: #C9391D;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  /* Icon Colors */
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  /* Toast Container Styles */
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  /* Text Colors */
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  /* Spinner Colors */
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Progress Bar Colors */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #121212, /* Success Green */
    #121212, /* Updated Info Blue */
    #121212, /* Warning Orange */
    #121212  /* Error Red */
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
